export function readQuery({ cache, query, variables, pathToData }) {
    const queryData = cache.readQuery({ query, variables });

    return queryData?.[pathToData];
}

export function writeQuery({
    cache,
    query,
    variables,
    pathToData,
    subPathToData,
    update,
}) {
    const updatePathOrSubpath = subPathToData
        ? { [subPathToData]: update }
        : update;

    return cache.writeQuery({
        query,
        variables,
        data: {
            [pathToData]: updatePathOrSubpath,
        },
    });
}

export function addEntityOrEntitiesToArray({
    entityArray,
    newEntityOrEntities,
}) {
    return Array.isArray(newEntityOrEntities)
        ? [...entityArray, ...newEntityOrEntities]
        : [...entityArray, newEntityOrEntities];
}

export function removeEntityFromArray({
    entityArray,
    id,
    entityProperty = "id",
}) {
    return entityArray.filter((entity) => entity[entityProperty] !== id);
}

export function replaceEntityInArray({ entityArray, replacementEntity }) {
    return entityArray.map((entity) =>
        entity.id === replacementEntity.id ? replacementEntity : entity
    );
}

export function upsertEntityInArray({ entityArray, entity }) {
    const existsInArray = entityArray.find(
        (entityElement) => entityElement.id === entity.id
    );

    if (existsInArray) {
        return replaceEntityInArray({ entityArray, replacementEntity: entity });
    }

    return addEntityOrEntitiesToArray({
        entityArray,
        newEntityOrEntities: entity,
    });
}
